import React, { Component } from 'react';
import Layout from '../components/Layout';
import {FaTools} from 'react-icons/fa';
import {MdDescription} from 'react-icons/md';
import {Helmet} from 'react-helmet';
import Img from 'gatsby-image';
import {graphql} from 'gatsby';

class About extends Component {
    render() {
        const { data } = this.props
        return (
            <div className="about">
                <Helmet>
                    <title>เกี่ยวกับเรา</title>
                </Helmet>
                <Layout>
                    <div className="header-page">
                        <div className="header-page-text">
                            เกี่ยวกับเรา
                        </div>
                        <div className="header-page-overay"></div>
                        <Img fluid={data.file.childImageSharp.fluid} alt="เกี่ยวกับเรา" className="header-page-img" />
                    </div>
                    <div className="container">
                        <div className="row mt-5">
                            <div className="col-12 text-center">
                                <h3><MdDescription /> เกี่ยวกับเรา</h3>
                            </div>
                            <div className="col-12">
                                <p className="indent-50 font-size-22">
                                    เราทำงานเป็น Developer ที่พัฒนาเว็บไซต์มาหลายปี ด้วยประสบการณ์ต่างๆที่ได้สะสมมา ไม่ว่าจะเป็น
                                    งานจากลูกค้า งานจากการทดลองลงมือทำสิ่งใหม่ๆที่เกิดขึ้นตลอดเวลา และนำความรู้ต่างๆมาแบ่งปันกับคนที่สนใจ
                                    เรื่องเดียวกันได้มาศึกษาหาความรู้จากเราได้ ไม่ว่าจะให้เราสอนเขียนโปรแกรม ให้เราทำเว็บไซต์ให้ หรือจะเข้ามาอ่านบทความ
                                    จากเรา เราก็จะพยายามนำสิ่งที่เรามีทั้งหมดมาแบ่งปันให้กับคนที่สนใจได้เรียนรู้ไปกับเราให้ได้มากที่สุด
                                </p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12 text-center">
                                <h4><FaTools /> ทักษะทางด้าน Web Developer</h4>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                <h5>Front-End</h5>
                                <ul>
                                    <li>HTML</li>
                                    <li>CSS</li>
                                    <li>JavaScript</li>
                                    <li>Jquery</li>
                                    <li>Bootstrap</li>
                                    <li>React</li>
                                </ul>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                <h5>Back-End</h5>
                                <ul>
                                    <li>ASP.NET</li>
                                    <li>ASP.NET Core</li>
                                    <li>SQL Server</li>
                                    <li>My SQL</li>
                                    <li>C#</li>
                                </ul>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                <h5>Tools</h5>
                                <ul>
                                    <li>Visual Studio</li>
                                    <li>Visual Studio Code</li>
                                    <li>Source Tree</li>
                                    <li>SQL Server Management Studio</li>
                                </ul>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                <h5>Operating Syatem</h5>
                                <ul>
                                    <li>Windows</li>
                                    <li>Linux</li>
                                </ul>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                <h5>Source Control</h5>
                                <ul>
                                    <li>Git</li>
                                </ul>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                <h5>Web Server</h5>
                                <ul>
                                    <li>IIS</li>
                                </ul>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                <h5>Other</h5>
                                <ul>
                                    <li>Docker</li>
                                    <li>Kubernetes</li>
                                    <li>Jenkins</li>
                                    <li>SonarQube</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Layout>
            </div>
        );
    }
}

export default About;

export const query = graphql`
query{
      file(relativePath: {eq: "about.jpg"}) {
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      }
  }  
`